var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('HeaderVue',{staticClass:"mb-3",attrs:{"heading":"Collections"},scopedSlots:_vm._u([{key:"buttons",fn:function(){return [_c('AddCollectionModal',{ref:"addCollectionModal",attrs:{"categoryTitle":{singular:'collection', plural:'collections'},"isLoading":_vm.isLoadAddCollection},on:{"submit":function($event){return _vm.saveCollection($event)}}}),(_vm.getNavbarData.userType == 'shopify')?_c('button',{staticClass:"btn px-3 py-2 ml-2 shopify-btn",on:{"click":function($event){return _vm.openShopifyCollectionModal()}}},[_vm._v(" import from "),_c('i',{staticClass:"fab fa-shopify"})]):_vm._e(),(_vm.getNavbarData.userType == 'wix')?_c('SyncWixCollections'):_vm._e(),(_vm.getNavbarData.userType == 'bc')?_c('SyncBigCommerceCollections',{staticClass:"ml-2"}):_vm._e()]},proxy:true}])}),_c('div',{staticClass:"card border-radius"},[_c('div',{staticClass:"card-body",staticStyle:{"overflow":"auto"}},[_c('div',{},[_c('FilterBox',{attrs:{"search":true,"placeholder":"Search Collections..."},model:{value:(_vm.titleInput),callback:function ($$v) {_vm.titleInput=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"titleInput"}}),_c('div',{staticClass:"loader d-flex align-items-center justify-content-between"},[(_vm.isLoading)?_c('div',{staticClass:"d-flex align-items-center"},[_c('b-spinner',{staticStyle:{"color":"var(--primary-color)"},attrs:{"small":""}}),_c('span',{staticClass:"ml-2"},[_vm._v("Loading Collections")])],1):_vm._e()]),(_vm.isLoadingFirstTime)?_c('div',{staticClass:"skeleton"},[_c('b-skeleton-table',{staticClass:"py-3",attrs:{"rows":5,"columns":4,"table-props":{
                bordered: true,
                striped: true,
              }}})],1):(_vm.items.length > 0)?_c('div',{staticClass:"data-table"},[_c('b-table',{staticClass:"leadTable res",attrs:{"head-class":"text-center","responsive":true,"stickyColumn":true,"hover":"","fields":_vm.fields,"items":_vm.items},scopedSlots:_vm._u([{key:"head(checkbox)",fn:function(){return [_c('b-form-checkbox',{staticClass:"checkbox",attrs:{"size":"lg"},on:{"input":function($event){return _vm.toggleQuizellCollections()}},model:{value:(_vm.selectAllQuizellCollections),callback:function ($$v) {_vm.selectAllQuizellCollections=$$v},expression:"selectAllQuizellCollections"}})]},proxy:true},{key:"cell(checkbox)",fn:function(data){return [_c('b-form-checkbox',{staticClass:"checkbox",attrs:{"value":data.item.id,"size":"lg"},model:{value:(_vm.selectedQuizellCollections),callback:function ($$v) {_vm.selectedQuizellCollections=$$v},expression:"selectedQuizellCollections"}})]}},{key:"cell(title)",fn:function(data){return [_c('div',{staticClass:"d-flex align-items-center"},[_c('lazy-image',{key:data.item.image,staticClass:"circle-image mr-2",attrs:{"src":data.item.image
                        ? data.item.image
                        : 'https://images.quizell.com/website/default_start_page.png'}}),_c('span',{staticClass:"text-hover-underline",on:{"click":()=>{
                    _vm.editCollectionId=data.item.id
                    _vm.$refs.EditCollectionModal.open({title:data.item.title,image:data.item.image})
                  }}},[_vm._v(_vm._s(data.item.title))])],1)]}},{key:"cell(actions)",fn:function(data){return [_c('b-dropdown',{attrs:{"dropright":"","id":"collection-detail"}},[_c('template',{slot:"button-content"},[_c('div',{staticClass:"p-2"},[_c('i',{staticClass:"text-dark fa-solid fa-ellipsis-vertical"})])]),_c('b-dropdown-item',{staticClass:"topbarLink d-flex align-items-center",on:{"click":function($event){$event.stopPropagation();return _vm.$refs.viewProducts.openModal(data.item.id)}}},[_vm._v(" View Products")]),_c('b-dropdown-item',{staticClass:"topbarLink d-flex align-items-center",on:{"click":()=>{
                    _vm.editCollectionId=data.item.id
                    _vm.$refs.EditCollectionModal.open({title:data.item.title,image:data.item.image})
                  }}},[_vm._v(" Edit Collection")]),(_vm.getUserType=='shopify')?_c('b-dropdown-item',{staticClass:"topbarLink d-flex align-items-center",on:{"click":()=>{
                    _vm.syncCollection=data.item
    _vm.$refs.syncCollection.open();
                  }}},[_vm._v(" Sync Product")]):_vm._e()],2)]}}])})],1):[_c('div',{staticClass:"d-flex justify-content-center align-items-center",staticStyle:{"height":"50vh"}},[_c('EmptyTable',{attrs:{"showButton":false},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" No collection found ")]},proxy:true},{key:"description",fn:function(){return [_vm._v(" Time to fill your shelves or refine your search. Remember, your results will appear right here. ")]},proxy:true}])})],1)],_c('div',{},[(_vm.items.length)?_c('QuizellPagination',{attrs:{"total-rows":_vm.totalRows,"currentPage":_vm.currentPage,"per-page":_vm.perPage},on:{"update":_vm.updatePagination,"UpdatePerPage":_vm.updatePerPage}}):_vm._e()],1)],2)])]),_c('ModalVue',{ref:"Modal",attrs:{"id":"copyShopifyCollections","title":"Import from Shopify","size":"lg"}},[_c('div',{staticClass:"my-2",class:!_vm.isLoadingSync ? 'd-none' : 'd-block'},[_c('ProgressbarVue',{ref:"progressbar"}),_c('span',{staticClass:"text-success text-center",staticStyle:{"font-size":"12px","text-align":"center"}},[_vm._v(" Collections syncing soon, continue tasks uninterrupted. ")])],1),_c('FilterBox',{attrs:{"search":true,"placeholder":"Search Collections..."},on:{"input":function($event){return _vm.searchCollectionQueryHandle($event)}}}),_c('div',{staticClass:"loader px-2"},[_c('div',{staticClass:"d-flex py-2",class:[
            _vm.isLoadingShopifyCollections
              ? 'justify-content-between'
              : 'justify-content-start',
          ]},[(_vm.isLoadingShopifyCollections)?_c('div',{staticClass:"d-flex align-items-center"},[_c('b-spinner',{staticStyle:{"color":"var(--primary-color)"},attrs:{"small":""}}),_c('span',{staticClass:"ml-2"},[_vm._v("Loading Collections")])],1):_vm._e(),(_vm.shopifySelectedCollections.length)?_c('div',{},[_vm._v(" Selected Collections : "+_vm._s(_vm.shopifySelectedCollections.length)+" ")]):_vm._e()])]),(_vm.isLoadingShopifyCollectionFirstTime)?_c('b-skeleton-table',{attrs:{"rows":5,"columns":3,"table-props":{ bordered: true, striped: true }}}):(_vm.shopifyCollections.length)?_c('div',{staticClass:"data-table"},[_c('div',{staticStyle:{"max-height":"55vh","min-height":"55vh","overflow":"auto"}},[_c('b-table',{staticClass:"leadTable",attrs:{"head-class":"text-center","stickyColumn":true,"hover":"","fields":_vm.shopifyCollectionsFields,"items":_vm.shopifyCollections},scopedSlots:_vm._u([{key:"head(checkbox)",fn:function(){return [_c('b-form-checkbox',{staticClass:"checkbox selectAll",attrs:{"size":"lg","disabled":_vm.isLoadingShopifyCollections ||
                  _vm.isLoadingSelectedShopifyCollections ||
                  _vm.isLoadingSync},on:{"change":function($event){return _vm.shopifyCollectionSelectAllToggle($event)}},model:{value:(_vm.shopifySelectAllCheck),callback:function ($$v) {_vm.shopifySelectAllCheck=$$v},expression:"shopifySelectAllCheck"}})]},proxy:true},{key:"cell(productsCount)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.productsCount.count)+" ")]}},{key:"cell(checkbox)",fn:function(data){return [_c('b-form-checkbox',{staticClass:"checkbox",attrs:{"value":data.item,"disabled":_vm.isLoadingShopifyCollections ||
                  _vm.isLoadingSelectedShopifyCollections ||
                  _vm.isLoadingSync,"size":"lg"},model:{value:(_vm.shopifySelectedCollections),callback:function ($$v) {_vm.shopifySelectedCollections=$$v},expression:"shopifySelectedCollections"}})]}}])})],1)]):[_c('div',{staticClass:"d-flex justify-content-center align-items-center",staticStyle:{"height":"50vh"}},[_c('EmptyTable',{attrs:{"showButton":false},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" No collection found ")]},proxy:true},{key:"description",fn:function(){return [_vm._v(" Time to fill your shelves or refine your search. Remember, your results will appear right here. ")]},proxy:true}])})],1)],_c('div',{staticClass:"d-flex justify-content-between mt-3"},[_c('ShopifyPagination',{attrs:{"hasNext":_vm.pageInfo.hasNextPage,"hasPrevious":_vm.pageInfo.hasPreviousPage},on:{"onNext":() => {
              _vm.queryObject.before = '';
              _vm.queryObject.after = _vm.pageInfo.endCursor;
              _vm.getCollectionsFromShopify();
            },"onPrevious":() => {
              _vm.queryObject.after = '';
              _vm.queryObject.before = _vm.pageInfo.startCursor;
              _vm.getCollectionsFromShopify();
            }}}),_c('div',{},[_c('div',{staticClass:"d-flex py-2 py-lg-0 justify-content-lg-end w-100 flex-row align-items-center"},[(_vm.shopifySelectedCollections.length)?_c('BorderButton',{staticClass:"secondary-color-button mr-3",staticStyle:{"border-radius":"4px !important"},attrs:{"custom":true,"disabled":_vm.isLoadingSync ||
                _vm.isLoadingShopifyCollections ||
                _vm.isLoadingSelectedShopifyCollections,"isLoading":_vm.isLoadingSelectedShopifyCollections},on:{"click":function($event){return _vm.syncSelected()}}},[_vm._v("Sync Selected")]):_c('button',{staticClass:"btn d-flex px-3 justify-content-center align-items-center py-2 shopify-btn",attrs:{"disabled":_vm.isLoadingSync ||
                _vm.isLoadingShopifyCollections ||
                _vm.isLoadingSelectedShopifyCollections},on:{"click":function($event){return _vm.syncAllCollections()}}},[(_vm.isLoadingSync)?_c('div',{staticClass:"d-flex align-items-center"},[_c('b-spinner',{attrs:{"small":""}}),_c('span',{staticClass:"ml-1"},[_vm._v("Syncing...")])],1):_c('span',[_vm._v("Sync all")])])],1)])],1)],2),_c('DeleteFooterModal',{ref:"deleteModal",attrs:{"isLoading":_vm.isLoadingDeleteCollection,"itemLength":_vm.selectedQuizellCollections.length,"name":"collections"},on:{"click":function($event){return _vm.deleteSelectedCollections()}}}),_c('ViewCategoryProducts',{ref:"viewProducts",attrs:{"category":"collection"}}),_c('ModalVue',{ref:"syncCollection",attrs:{"title":"Sync Collection","size":"md","id":"syncCollection"}},[_c('div',{staticClass:"p-4"},[_c('b-form-checkbox',{staticClass:"checkbox d-flex align-items-center cursor-pointer",attrs:{"name":"withVariant","small":""},model:{value:(_vm.syncCollectionWithVariant),callback:function ($$v) {_vm.syncCollectionWithVariant=$$v},expression:"syncCollectionWithVariant"}},[_vm._v(" Sync collection products with variants. ")]),_c('div',{staticClass:"pt-3",staticStyle:{"font-size":"14px","color":"#8b8b8b"}},[_vm._v(" This may take some time, you can continue other tasks without interruption. ")]),_c('div',{staticClass:"d-flex justify-content-center mt-2"},[_c('Button',{attrs:{"isLoading":_vm.isLoadingSyncCollection},on:{"click":function($event){return _vm.syncCollectionHandle()}}},[_vm._v("Sync Collection")])],1)],1)]),_c('AddCollectionModal',{ref:"EditCollectionModal",attrs:{"editMode":true,"categoryTitle":{singular:'collection', plural:'collections'},"isLoading":_vm.isLoadAddCollection},on:{"submit":function($event){return _vm.saveCollection($event,true)}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }